import React from 'react';

const Jumpmark = ({ id, marginTop: margintop = 60 }) => (
  <div
    id={id}
    style={{ marginTop: `-${margintop}px`, paddingTop: margintop }}
  />
);

export { Jumpmark as default };
