export const CheckboxData = [
  {
    title: 'Trial curation',
    link: {
      label: 'Learn more',
      to: '/frequently-asked-questions/#5',
    },
    id: 'trial_curation',
    checkboxes: [
      {
        label: 'Include uncurated trials',
        name: 'include_uncurated',
        num: 0,
      },
    ],
  },
  {
    title: 'Trial recruitment',
    id: 'trial_recruitment',
    checkboxes: [
      {
        label: 'Recruiting now/soon',
        name: 'is_recruiting_now_soon',
        num: 0,
      },
    ],
  },
  {
    title: 'Trial type',
    id: 'trial_type',
    isGrouped: true,
    checkboxes: [
      { label: 'Observation', name: 'observational', num: 0 },
      { label: 'Intervention', name: 'interventional', num: 0 },
    ],
  },
];
