import styles from 'components/NotifyConfirm/NotifyConfirm.module.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import {
  unstable_Form as Form,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';

const NotifyConfirm = ({ email, form }) => {
  return (
    <div className={styles.confirmContainer}>
      <div className={styles.iconWrapper}>
        <IconWrapper
          Icon={IconComponents.LargeEmaiLFastIcon}
          className={styles.mailIcon}
        />
      </div>
      <Heading theme="primary" size="h2" className={styles.heading}>
        Check your inbox
      </Heading>
      <Content size="copy75" className={styles.confirmText}>
        To make sure you’re really you, tap the link in the email we just sent
        to <strong>{email}</strong> (remember to check your Spam).
      </Content>
      <div className={styles.resend}>
        <Form {...form}>
          <FormSubmitButton
            as={Button}
            theme="link"
            size="default"
            className={styles.resendLink}
            {...form}
          >
            Resend email
          </FormSubmitButton>
        </Form>
      </div>
    </div>
  );
};

export default NotifyConfirm;
