import styles from './Spinner.module.scss';
import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Spinner = ({ className }) => {
  return (
    <div className={classNames(className)}>
      <div className={styles.spinner} />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

export default Spinner;
