// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const color = {
  color: {
    indigo: {
      100: {
        value: 'rgba(0, 69, 124, 1)',
      },
      125: {
        value: 'rgba(6, 60, 105, 1)',
      },
      150: {
        value: 'rgba(5, 46, 79, 1)',
      },
      200: {
        value: 'rgba(3, 30, 51, 1)',
      },
    },
    green: {
      50: {
        value: 'rgba(74, 247, 206, 1)',
      },
      100: {
        value: 'rgba(15, 145, 114, 1)',
      },
    },
    orange: {
      50: {
        value: 'rgba(242, 222, 172, 1)',
      },
      100: {
        value: 'rgba(227, 177, 48, 1)',
      },
    },
    red: {
      50: {
        value: 'rgba(242, 211, 213, 1)',
      },
      100: {
        value: 'rgba(197, 14, 25, 1)',
      },
    },
    radiant: {
      5: {
        value: 'rgba(250, 253, 255, 1)',
      },
      10: {
        value: 'rgba(245, 251, 255, 1)',
      },
      15: {
        value: 'rgba(237, 245, 250, 1)',
      },
      25: {
        value: 'rgba(222, 239, 252, 1)',
      },
      50: {
        value: 'rgba(165, 209, 246, 1)',
      },
      100: {
        value: 'rgba(55, 154, 236, 1)',
      },
    },
    white: {
      20: {
        value: 'rgba(255, 255, 255, 0.2)',
      },
      100: {
        value: 'rgba(255, 255, 255, 1)',
      },
    },
    crimson: {},
    gray: {
      5: {
        value: 'rgba(242, 245, 247, 1)',
      },
      10: {
        value: 'rgba(235, 240, 245, 1)',
      },
      15: {
        value: 'rgba(225, 233, 240, 1)',
      },
      20: {
        value: 'rgba(192, 201, 209, 1)',
      },
      30: {
        value: 'rgba(163, 173, 181, 1)',
      },
      40: {
        value: 'rgba(135, 145, 153, 1)',
      },
      50: {
        value: 'rgba(112, 121, 128, 1)',
      },
      60: {
        value: 'rgba(84, 94, 102, 1)',
      },
      70: {
        value: 'rgba(65, 71, 77, 1)',
      },
      80: {
        value: 'rgba(39, 45, 51, 1)',
      },
      90: {
        value: 'rgba(18, 26, 33, 1)',
      },
    },
  },
};

module.exports = color;
