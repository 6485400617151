// FIXME introduce button link style
/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from 'components/NotifyOverlay/NotifyOverlay.module.scss';
import { useSearchStore } from 'store';
import { getSearchedCriteria } from 'services/getSearchedCriteria';
import React from 'react';
import { Separator } from 'reakit/Separator';
import {
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormMessage as FormMessage,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Form from '@moonshineragency/ui/src/components/Form/Form';

const NotifyOverlay = ({ form, onClick, benefits, interventions }) => {
  const store = useSearchStore();
  const { formValues } = store;

  const filteredSearchCriteria = getSearchedCriteria(
    formValues,
    benefits,
    interventions,
  );

  const submitArea = (
    <div className={styles.linksWrapper}>
      <div className={styles.cancel}>
        <Link
          size="75"
          onClick={onClick}
          type="text"
          as="button"
          className={styles.cancelLink}
        >
          Cancel
        </Link>
      </div>
      <FormSubmitButton
        className={styles.notifyButtonContainer}
        as={Button}
        theme="primary"
        size="default"
        isFullWidth
        {...form}
      >
        Notify me
      </FormSubmitButton>
    </div>
  );

  return (
    <>
      <Content className={styles.content} size="copy75" noSpacing>
        Via email when there are new trials that match your search criteria.
      </Content>
      <ul className={styles.selectedWrapper}>
        {filteredSearchCriteria.map(item => (
          <li className={styles.selectedItem} key={item}>
            {item}
          </li>
        ))}
      </ul>
      <Separator className={styles.separator} />
      <Form
        hasGeneralFormErrorMessage={
          form.values.checked || form.values.email !== ''
        }
        submitArea={submitArea}
        form={form}
        className={styles.inputWrapper}
      >
        <InputField
          name="email"
          label="Your email adress"
          {...form}
          noMessage
          className={styles.inputField}
        />
        <FormMessage {...form} name="email" className={styles.errorMessage} />
        <div className={styles.checkboxWrapper}>
          <FormCheckbox
            className={styles.checkbox}
            as={Checkbox}
            label={
              <div className={styles.checkboxText}>
                <span>I confirm I have read and agree to the </span>
                <Link
                  asType="external"
                  variant="noUnderline"
                  className={styles.confirmLink}
                  to="/terms-of-service"
                  target="_blank"
                >
                  <span>terms of service</span>
                </Link>{' '}
                <span>and the</span>{' '}
                <Link
                  asType="external"
                  variant="noUnderline"
                  className={styles.confirmLink}
                  to="/data-privacy"
                  target="_blank"
                >
                  data privacy statement
                </Link>
              </div>
            }
            name="data_privacy"
            {...form}
          />
        </div>

        <FormMessage
          {...form}
          name="data_privacy"
          className={styles.errorMessage}
        />
      </Form>
    </>
  );
};

export default NotifyOverlay;
