import styles from './FilterView.module.scss';
import Jumpmark from 'components/Jumpmark/Jumpmark';
import DataView from 'components/DataView/DataView';
import ReakitSeparator from 'components/Separator/Separator';
import React, { useState } from 'react';

import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import {
  unstable_FormGroup as FormGroup,
  unstable_FormLabel as FormLabel,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
} from 'reakit/Form';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Slider from 'react-input-slider';
import { color } from '@moonshineragency/ui/src/assets/tokens/color';
import classNames from 'classnames';
import { DisclosureContent } from 'reakit/Disclosure';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';

import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';

const Selector = ({ children, name, popover }) => {
  return (
    <div className={styles.item}>
      {popover && (
        <DisclosureContent
          className={styles.popOver}
          {...popover}
          aria-label={name}
        >
          {() => popover.visible && children}
        </DisclosureContent>
      )}
    </div>
  );
};

const LocationFilter = ({ form, minDistance, locationProvided }) => {
  const [isShowDistanceInput, showDistanceInput] = useState(false);

  const xValue = form.values?.distance?.distance_from || 10000;
  const popover = usePopoverState();
  const numberFormat = new Intl.NumberFormat('de-DE');

  return (
    <div className={styles.location} id="filters">
      {locationProvided && (
        <>
          <Heading
            className={styles.distanceHeading}
            as="legend"
            noSpacing
            theme="neutral"
            size="h5"
          >
            Distance from {form.values.location_name}
          </Heading>
          <Content className={styles.content} size="uiText75">
            within {xValue}
            {xValue >= 10000 ? '+' : ''}{' '}
            <PopoverDisclosure
              className={styles.popoverDisclosure}
              onKeyDown={() => {
                showDistanceInput(true);
              }}
              {...popover}
            >
              {form.values.unit}{' '}
              <IconWrapper
                className={classNames(styles.popoverIcon, {
                  [styles.ispopoverOpen]: popover.visible,
                })}
                Icon={IconComponents.SmallArrowDown}
              />
            </PopoverDisclosure>
            <Popover className={styles.popoverWrapper} {...popover}>
              <FormRadioGroup
                className={styles.dropdownWrapper}
                {...form}
                onChange={popover.hide}
                name="unit"
              >
                <label
                  className={classNames(styles.dropdownLabel, {
                    [styles.activeDropdownItem]: form.values.unit === 'miles',
                  })}
                >
                  <IconWrapper
                    className={classNames(styles.isActiveIcon)}
                    Icon={IconComponents.xSmallCheck}
                  />
                  <FormRadio {...form} name="unit" value="miles" /> Miles
                </label>
                <label
                  className={classNames(styles.dropdownLabel, {
                    [styles.activeDropdownItem]: form.values.unit === 'km',
                  })}
                >
                  <IconWrapper
                    className={classNames(styles.isActiveIcon)}
                    Icon={IconComponents.xSmallCheck}
                  />
                  <FormRadio {...form} name="unit" value="km" /> Kilometres
                </label>
              </FormRadioGroup>
            </Popover>
          </Content>
          <div>
            <Slider
              axis="x"
              xmin={minDistance === 0 ? 10 : Math.ceil(minDistance / 10) * 10}
              xstep={100}
              xmax={10000}
              x={xValue}
              onChange={({ x }) => {
                form.update('distance', {
                  distance_from: x,
                });
              }}
              styles={{
                track: {
                  backgroundColor: color.radiant['100'].value,
                  height: 2,
                  width: '100%',
                },
                active: {
                  backgroundColor: color.radiant['100'].value,
                },
                thumb: {
                  width: 24,
                  height: 24,
                  boxShadow:
                    '0px 0px 1px rgba(39, 45, 51, 0.5), 0px 1px 1px rgba(39, 45, 51, 0.2)',
                  border: `1px solid ${color.gray['50'].value}`,
                  backgroundColor: color.gray['15'].value,
                },
              }}
            />
          </div>
          <div className={styles.locationPosition}>
            <Content
              className={styles.distances}
              size="uiText75"
              aria-label="Minimum Distance"
            >
              {minDistance === 0 ? '10' : Math.ceil(minDistance / 10) * 10}
            </Content>

            <Content
              className={styles.distances}
              size="uiText75"
              aria-label="Maximum Distance"
            >
              {numberFormat.format(10000)}
            </Content>
          </div>
          {isShowDistanceInput && (
            <div>
              <InputField
                {...form}
                onChange={ev => {
                  form.update('distance', {
                    distance_from: ev.currentTarget.value,
                  });
                }}
                value={xValue}
                type="number"
                name="manual_location_distance"
                label="Location Distance"
                noMessage
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
const FilterView = ({
  totalResults,
  popover,
  form,
  filterGroups,
  locationProvided,
  minDistance,
}) => {
  return (
    <Selector popover={popover}>
      <DataView
        className={styles.card}
        popover={popover}
        name={
          <div className={styles.dataviewHeader}>
            <Heading
              className={styles.dataviewHeading}
              theme="primary"
              size="h3"
            >
              Filter trials
            </Heading>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
            <Link
              size="75"
              type="ui"
              theme="regular"
              aria-label="Reset Selection"
              as="div"
              role="button"
              onClick={() => {
                form.reset();
              }}
            >
              Clear all
            </Link>
          </div>
        }
        noSpacing
      >
        <div className={classNames(styles.container)}>
          <LocationFilter
            form={form}
            locationProvided={locationProvided}
            minDistance={minDistance}
          />
          <Jumpmark id="filters" />

          {filterGroups.map(item => {
            const GroupTag = item.isGrouped ? FormGroup : 'div';
            const groupProps = item.isGrouped ? { ...form } : {};
            return (
              <GroupTag
                {...groupProps}
                name={item.id}
                key={item.id}
                onChange={ev => {
                  // resets "interventions" when trialtype interventional gets unselected
                  if (
                    ev.target.name === 'trial_type' &&
                    ev.target.value === 'interventional' &&
                    form.values.trial_type?.includes('interventional')
                  ) {
                    form.update('interventions', []);
                  }
                }}
                className={classNames(styles.formWrapper, {
                  [styles.hideInterventions]: item.id === 'interventions',
                  [styles.displayInterventions]:
                    item.id === 'interventions' &&
                    form.values?.trial_type?.includes('interventional'),
                })}
              >
                <div className={styles.checkboxWrapper}>
                  <ReakitSeparator
                    noSpacing
                    hide={!locationProvided && item.id === 'trial_curation'}
                  />
                  <FormLabel
                    {...form}
                    as="legend"
                    name={item.id}
                    className={styles.labelWrapper}
                  >
                    <div className={styles.titleWrapper}>
                      <Heading noSpacing theme="neutral" size="h5">
                        {item.title}
                      </Heading>
                      {item.link && (
                        <Link
                          type="ui"
                          size="50"
                          className={styles.link}
                          to={item.link.to}
                        >
                          {item.link.label}
                        </Link>
                      )}
                    </div>
                  </FormLabel>
                  <div className={styles.fields}>
                    {item.checkboxes.map(checkbox => {
                      const renderName = () => {
                        if (item.isGrouped) {
                          if (
                            item.id === 'potential_benefits' ||
                            item.id === 'interventions'
                          ) {
                            return `${item.id}${checkbox.name}`;
                          }
                          return item.id;
                        }
                        return checkbox.name;
                      };
                      return (
                        <label
                          key={checkbox.name}
                          className={classNames(styles.text, {
                            [styles.disabledText]: checkbox.num === 0,
                          })}
                        >
                          <FormCheckbox
                            {...form}
                            as={Checkbox}
                            disabled={checkbox.num === 0}
                            name={
                              item.isGrouped
                                ? item.id
                                : renderName(checkbox.name)
                            }
                            value={item.isGrouped ? checkbox.name : undefined}
                            label={
                              <>
                                <Content
                                  as="span"
                                  size="uiText75"
                                  theme="neutral"
                                  className={styles.label}
                                >
                                  {checkbox.label}
                                </Content>
                                <Content
                                  as="span"
                                  size="uiText75"
                                  theme="neutral"
                                  className={classNames(styles.labelNumber, {
                                    [styles.disabledNumber]: checkbox.num === 0,
                                  })}
                                >
                                  {checkbox.num}
                                </Content>
                              </>
                            }
                            onClick={() => {
                              // eslint-disable-next-line no-underscore-dangle
                              const _paq = window._paq || [];
                              _paq.push([
                                'trackEvent',
                                'Filter',
                                `Filter ${checkbox.label} `,
                                `${item.title}`,
                              ]);
                            }}
                          />
                        </label>
                      );
                    })}
                  </div>
                </div>
              </GroupTag>
            );
          })}
        </div>
        <div className={styles.dataViewActionWrapper}>
          <Button
            aria-hidden="true"
            isFullWidth
            onClick={() => {
              popover.hide();
            }}
          >
            Show {totalResults} Results
          </Button>
        </div>
      </DataView>
    </Selector>
  );
};
export default FilterView;
