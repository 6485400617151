import styles from 'components/Notify/Notify.module.scss';
import NotifyOverlay from 'components/NotifyOverlay/NotifyOverlay';
import NotifyConfirm from 'components/NotifyConfirm/NotifyConfirm';
import Jumpmark from 'components/Jumpmark/Jumpmark';
import Client from 'api/client';
import { SearchSettingsContext } from 'context';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import { unstable_useFormState as useFormState } from 'reakit/Form';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Modal from '@moonshineragency/ui/src/components/Modal/Modal';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';

const Notify = ({ hasJumpMark = false, benefits, interventions }) => {
  const context = React.useContext(SearchSettingsContext);
  const formValues = context?.form?.values;
  const [mappedNotificationData, setMappedNotificationData] = useState({});

  const mapNotificationData = notificationData => {
    return {
      ...notificationData,
      filters_counter: notificationData.filters_counter ?? null,
      severity: notificationData.severity ?? null,
      time_since_injury: notificationData.time_since_injury ?? null,
      trial_type: notificationData.trial_type ?? [],
      potential_benefits:
        notificationData.potential_benefits?.map(benefit =>
          benefit?.toString(),
        ) ?? [],
      injury_level: notificationData.injuryLevel ?? [],
      recruitment: notificationData.is_recruiting_now_soon?.toString() ?? null,
      include_uncurated: notificationData.include_uncurated ?? null,
      location: {
        city: notificationData.location?.label ?? null,
        longitude: notificationData.location?.value?.lng?.toString() ?? null,
        latitude: notificationData.location?.value?.lat?.toString() ?? null,
      },
      distance: {
        distance_from: notificationData.distance.distance_from ?? null,
        distance_unit: notificationData.unit ?? null,
      },
      sort_by: notificationData.sort_by ?? null,
    };
  };

  useEffect(() => {
    const data = mapNotificationData(formValues);
    setMappedNotificationData(data);
  }, [formValues]);

  const dialog = useDialogState();
  const successDialog = useDialogState();

  const form = useFormState({
    values: {
      ...mappedNotificationData,
      email: '',
      data_privacy: false,
    },
    onValidate: values => {
      let errors = {};
      if (!values.email) {
        errors = {
          ...errors,
          email: (
            <div className={styles.errorWrapper}>
              <IconWrapper
                Icon={IconComponents.SmallWarningCircle}
                className={styles.smallWarningCircle}
              />
              Please enter email
            </div>
          ),
        };
      }
      if (!values.data_privacy) {
        errors = {
          ...errors,
          data_privacy: (
            <div className={styles.errorWrapper}>
              <IconWrapper
                Icon={IconComponents.SmallWarningCircle}
                className={styles.smallWarningCircle}
              />
              Please confirm the terms of service and data privacy statement
            </div>
          ),
        };
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async (values, errors) => {
      // eslint-disable-next-line no-underscore-dangle
      const _paq = window._paq || [];
      if (!errors) {
        _paq.push([
          'trackEvent',
          'Notify me Button',
          `Email sent`,
          `Notify me`,
        ]);
      }
      try {
        await Client.notifications.post(values);
      } catch (e) {
        throw errors;
      }
      successDialog.toggle();
    },
  });

  useEffect(() => {
    Object.keys(mappedNotificationData)?.map(key => {
      return form.update(key, mappedNotificationData[key]);
    });
  }, [mappedNotificationData]);

  const handleClick = dialog.hide;

  return (
    <div className={styles.notifyContainer}>
      {hasJumpMark && <Jumpmark id="notify-me" />}

      <div className={styles.bellWrapper}>
        <IconWrapper
          Icon={IconComponents.LargeBellIcon}
          alt="bell"
          className={styles.bellImg}
        />
      </div>
      <Heading theme="primary" size="h5" noSpacing>
        Get updates about new trials like these?
      </Heading>
      <Content
        size="copy75"
        theme="contrast"
        as="div"
        className={styles.notifyText}
      >
        No registration needed
      </Content>
      <DialogDisclosure
        onClick={() => {
          // eslint-disable-next-line no-underscore-dangle
          const _paq = window._paq || [];
          _paq.push([
            'trackEvent',
            'Button',
            `Notify me overlay Button clicked`,
            `Notify me`,
          ]);
        }}
        onKeyDown={() => {
          // eslint-disable-next-line no-underscore-dangle
          const _paq = window._paq || [];
          _paq.push([
            'trackEvent',
            'Button',
            `Notify me overlay Button clicked`,
            `Notify me`,
          ]);
        }}
        as={Button}
        {...dialog}
        theme="primary"
        size="small"
      >
        Notify me
      </DialogDisclosure>
      <Modal
        theme="default"
        header={
          <Heading theme="primary" size="h2" noSpacing>
            Notify me
          </Heading>
        }
        dialog={dialog}
        className={styles.modalWrappper}
      >
        <NotifyOverlay
          form={form}
          onClick={handleClick}
          benefits={benefits}
          interventions={interventions}
        />
      </Modal>

      <Modal dialog={successDialog} className={styles.modalWrappper}>
        <NotifyConfirm email={form.values.email} form={form} />
      </Modal>
    </div>
  );
};

Notify.propTypes = {
  benefits: PropTypes.arrayOf(Object).isRequired,
  interventions: PropTypes.arrayOf(Object).isRequired,
};

export default Notify;
