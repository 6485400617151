import Jumpmark from '../../components/Jumpmark/Jumpmark';
import useMediaQuery from 'hooks/useMediaQuery';
import Container from 'components/Container/Container';
import Notify from 'components/Notify/Notify';
import styles from 'templates/trials/TrialsOverview.scss';
import Layout from 'components/Layout/Layout';
import TrialCard from 'components/TrialCard/TrialCard';
import FilterView from 'components/FilterView/FilterView';
import Client from 'api/client';
import Pagination from 'components/Pagination/Pagination';
import { CheckboxData } from 'components/FilterView/CheckboxData';
import { SearchSettingsContext } from 'context';
import React, { useEffect, useState } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import { useStaticQuery, graphql } from 'gatsby';
import { useDebounceCallback } from '@react-hook/debounce';
import Pill from '@moonshineragency/ui/src/components/Pill/Pill';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import { useDisclosureState, Disclosure } from 'reakit/Disclosure';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import {
  unstable_Form as Form,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
  unstable_FormLabel as FormLabel,
} from 'reakit/Form';
import classNames from 'classnames';
import Spinner from '@moonshineragency/ui/src/components/Spinner/Spinner';

const mergeFilterGroupsCount = (groups, counts) => {
  return groups.map(group => {
    const groupCount = counts[group.id];
    return {
      ...group,
      checkboxes: group.checkboxes.map(checkbox => ({
        ...checkbox,
        num: groupCount[checkbox.name],
      })),
    };
  });
};
const TrialsTemplate = () => {
  const context = React.useContext(SearchSettingsContext);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const isSSR = typeof window === 'undefined';
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const popoverSortBy = usePopoverState();

  // Sticky does not work when the body has hidden/auto overflow.
  if (typeof document !== `undefined`) {
    document.body.style.overflow = 'visible';
  }

  const popover = useDisclosureState();
  useEffect(() => {
    if (isDesktop) {
      popover.show();
    }
  }, [isDesktop]);
  const form = context?.form;
  const {
    allBenefit: { edges },
    allIntervention: { edges: interventionEdges },
  } = useStaticQuery(graphql`
    query MyQuery {
      allBenefit {
        edges {
          node {
            id
            name
            label
            icon_name
          }
        }
      }
      allIntervention {
        edges {
          node {
            id
            name
            label
            icon_name
          }
        }
      }
    }
  `);

  const baseBenefits = edges.map(({ node }) => ({
    ...node,
    num: 0,
    type: 'potential_benefits',
  }));
  const baseInterventions = interventionEdges.map(({ node }) => ({
    ...node,
    num: 0,
    type: 'interventions',
  }));
  const [trialsMeta, setTrialsMeta] = useState({
    lastPage: null,
    currentPage: 1,
    minDistance: 0,
    total: 0,
    location: null,
  });

  const [filterGroups, setFilterGroups] = useState([
    ...CheckboxData,
    {
      title: 'Intervention',
      id: 'interventions',
      isGrouped: true,
      checkboxes: baseInterventions,
    },
    {
      title: 'Potential benefits',
      id: 'potential_benefits',
      isGrouped: true,
      checkboxes: baseBenefits,
    },
  ]);
  const [trials, setTrials] = useState([]);

  const selectedFilters = filterGroups
    .map(item => {
      if (item.isGrouped) {
        return item.checkboxes.filter(checkbox =>
          form?.values[item.id]?.includes(checkbox.name),
        );
      }
      if (item.id === 'trial_curation' || item.id === 'trial_recruitment') {
        return item.checkboxes.filter(checkbox => form?.values[checkbox.name]);
      }
      return false;
    })
    .filter(Boolean);

  const dispatchSearchTrials = useDebounceCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { data, meta, counter },
      } = await Client.trials.search(trialsMeta.currentPage, {
        ...form?.values,
        distance: form.values.distance?.distance_from
          ? { ...form.values.distance, distance_unit: form.values.unit }
          : { distance_unit: form.values.unit },
        location: form.values.location?.value
          ? {
              ...form.values.location.value,
            }
          : null,
      });

      setTrialsMeta({
        currentPage: meta.current_page,
        minDistance: Math.round(meta.min_distance),
        lastPage: meta.last_page,
        total: meta.total,
        location: form.values.location_name,
      });

      setFilterGroups(mergeFilterGroupsCount(filterGroups, counter));
      setTrials([...data]);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } catch (e) {
      setTrials([]);

      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsLoading(false);
  }, 300);

  const locationProvided = trials.some(
    trial => trial.closest_location !== null,
  );

  useEffect(dispatchSearchTrials, [form?.values, trialsMeta.currentPage]);

  if (isSSR) {
    return null;
  }

  const mobileCards = trials.slice(0, trials.length - 1);
  const mobileLastCard = trials.slice(trials.length - 1);
  const renderCards = cards =>
    cards.map(
      ({
        trial: {
          nct_id: nctId,
          brief_title: briefTitle,
          about_trial: aboutThisTrial,
          primary_intervention: primaryIntervention,
          primary_benefit: primaryBenefit,
          curation_status: curationStatus,
          overall_recruitment_status: recruitingStatus,
          modified_at: modifiedDate,
        },
        closest_location: closestLocation,
      }) => {
        return (
          <TrialCard
            key={nctId}
            to={`/trials/${nctId}`}
            title={briefTitle}
            content={aboutThisTrial}
            recruitingStatus={recruitingStatus}
            location={
              closestLocation
                ? `${closestLocation.city}, ${
                    closestLocation.country
                  } ${Math.round(closestLocation.distance)} ${
                    form.values.unit
                  } away`
                : null
            }
            primaryBenefit={primaryBenefit}
            primaryIntervention={primaryIntervention}
            curation={curationStatus}
            modifiedDate={modifiedDate}
          />
        );
      },
    );

  const selectedItems = selectedFilters
    .filter(item => item.length !== 0)
    .flat();

  const clearAllSelectedItems = () => {
    form.update('is_recruiting_now_soon', false);
    form.update('include_uncurated', false);
    form.update('potential_benefits', []);
    form.update('trial_type', []);
    form.update('interventions', []);
  };

  const downloadCsvFile = async () => {
    setIsLoadingCsv(true);
    const { data } = await Client.csvTrialsData.post(form?.values);

    if (data) {
      setIsLoadingCsv(false);
    }
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    // eslint-disable-next-line compat/compat
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();

    // eslint-disable-next-line compat/compat
    URL.revokeObjectURL(link.href);
  };

  const transformValue = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Layout
      title="Matching spinal cord injury trials"
      description="See which trials are close to you and fit your spinal cord injury."
      isWide
      skipLinks={[
        {
          id: 'searchbar',
          title: 'Searchbar',
        },
        {
          id: 'filters',
          title: 'Filters',
        },
        {
          id: 'search-results',
          title: 'Results',
        },
        {
          id: 'notify-me',
          title: 'Search Agent',
        },
      ]}
    >
      <Container size="wide" className={styles.container}>
        {form && (
          <div className={styles.result}>
            <Jumpmark id="search-results" />
            <div className={styles.meta}>
              <Heading theme="primary" size="h1" noSpacing>
                {isLoading
                  ? 'Loading matching trials'
                  : `${trialsMeta.total} matching
                ${trialsMeta.total > 1 ? 'trials' : 'trial'}`}
              </Heading>

              <div
                className={classNames(styles.filterAction, {
                  [styles.noLocationProvided]: !locationProvided,
                })}
              >
                {locationProvided && (
                  <div className={styles.sortByWrapper}>
                    <div className={styles.sortByLabel}>
                      <FormLabel {...form} as="legend" name="sort_by">
                        Sort By
                      </FormLabel>
                    </div>
                    <PopoverDisclosure
                      className={styles.popoverDisclosure}
                      {...popoverSortBy}
                    >
                      {transformValue(form.values.sort_by)}{' '}
                      <IconWrapper
                        className={classNames(styles.popoverIcon, {
                          [styles.ispopoverOpen]: popoverSortBy.visible,
                        })}
                        Icon={IconComponents.SmallArrowDown}
                      />
                    </PopoverDisclosure>
                    <Popover
                      className={styles.popoverWrapper}
                      {...popoverSortBy}
                    >
                      <FormRadioGroup
                        className={styles.dropdownWrapper}
                        {...form}
                        onChange={popoverSortBy.hide}
                        name="sort_by"
                      >
                        <label
                          className={classNames(styles.dropdownLabel, {
                            [styles.activeDropdownItem]:
                              form.values.sort_by === 'distance',
                          })}
                        >
                          <IconWrapper
                            className={classNames(styles.isActiveIcon)}
                            Icon={IconComponents.xSmallCheck}
                          />
                          <FormRadio
                            {...form}
                            name="sort_by"
                            value="distance"
                          />{' '}
                          Distance
                        </label>
                        <label
                          className={classNames(styles.dropdownLabel, {
                            [styles.activeDropdownItem]:
                              form.values.sort_by === 'updated',
                          })}
                        >
                          <IconWrapper
                            className={classNames(styles.isActiveIcon)}
                            Icon={IconComponents.xSmallCheck}
                          />
                          <FormRadio {...form} name="sort_by" value="updated" />{' '}
                          Updated
                        </label>
                      </FormRadioGroup>
                    </Popover>
                  </div>
                )}
                <div className={styles.downloadButtonWrapper}>
                  <Button
                    size="default"
                    theme={!isLoadingCsv ? 'secondary' : 'contrast'}
                    className={styles.downloadButton}
                    onClick={downloadCsvFile}
                  >
                    {isLoadingCsv ? (
                      <Spinner className={styles.spinnerWrapper} />
                    ) : (
                      <IconWrapper
                        Icon={IconComponents.SmallDownload}
                        className={styles.downloadIcon}
                      />
                    )}
                    Download table
                  </Button>
                </div>

                <div className={styles.filterTrialsWrapper}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
                  <Link
                    className={styles.filterTrials}
                    role="button"
                    size="75"
                    type="ui"
                    as={Disclosure}
                    {...popover}
                  >
                    Filter trials
                    <span className={styles.activeFilterBadge}>
                      {Object.keys(selectedItems).length}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.activeFilters}>
              {form.values.distance?.distance_from && locationProvided && (
                <Pill
                  onClick={() => {
                    form.update('distance', {
                      ...form.values.distance,
                      distance_from: null,
                    });
                  }}
                >
                  Within {form.values.distance?.distance_from}{' '}
                  {form.values.unit}
                </Pill>
              )}

              {/* remove chip */}
              {selectedItems.map(selectedItem => {
                const removeChip = () => {
                  const interventionTypes = form.values.interventions;

                  // curation chip
                  if (selectedItem.name === 'include_uncurated') {
                    form.update('include_uncurated', false);
                    return;
                  }
                  // recruitment chip
                  if (selectedItem.name === 'is_recruiting_now_soon') {
                    form.update('is_recruiting_now_soon', false);
                    return;
                  }
                  // trial type chips
                  if (
                    selectedItem.name === 'observational' ||
                    selectedItem.name === 'interventional'
                  ) {
                    const trialType = form.values.trial_type;
                    form.update(
                      'trial_type',
                      trialType.filter(item => item !== selectedItem.name),
                    );
                  }

                  // remove intervention type chip will also remove all intervention field items
                  if (selectedItem.name === 'interventional') {
                    form.update('interventions', []);
                    return;
                  }

                  if (selectedItem.type === 'potential_benefits') {
                    // benefit chips
                    const benefits = form.values.potential_benefits;
                    if (benefits && benefits.length) {
                      form.update(
                        'potential_benefits',
                        benefits.filter(item => item !== selectedItem.name),
                      );
                      return;
                    }
                  }

                  if (selectedItem.type === 'interventions') {
                    // remove each intervention item but not intervention type
                    form.update(
                      'interventions',
                      interventionTypes.filter(
                        item => item !== selectedItem.name,
                      ),
                    );
                  }
                };

                return (
                  <Pill
                    onClick={() => {
                      removeChip(selectedItem);
                    }}
                  >
                    {selectedItem.label}
                  </Pill>
                );
              })}

              {selectedItems.length > 0 && (
                <>
                  <Button
                    size="75"
                    theme="link"
                    aria-label="Reset Selection"
                    onClick={clearAllSelectedItems}
                    className={styles.resetSelectedItemsButton}
                  >
                    Clear all
                  </Button>
                </>
              )}
            </div>
            <div className={styles.trials}>
              {isDesktop && renderCards(trials)}
              {!isDesktop && (
                <>
                  {renderCards(mobileCards)}
                  <Notify
                    benefits={baseBenefits}
                    interventions={baseInterventions}
                  />
                  {renderCards(mobileLastCard)}
                </>
              )}
            </div>
            {trialsMeta.lastPage !== null && (
              <Pagination
                lastPage={trialsMeta.lastPage}
                currentPage={trialsMeta.currentPage}
                setCurrentPage={page => {
                  setTrialsMeta(prev => ({
                    ...prev,
                    currentPage: page,
                  }));
                }}
              />
            )}
          </div>
        )}
        {isDesktop && (
          <div>
            <div className={styles.notifyMeWrapper}>
              <Notify
                hasJumpMark
                benefits={baseBenefits}
                interventions={baseInterventions}
              />
            </div>
          </div>
        )}
        <div className={styles.filterView}>
          {form && (
            <Form {...form} className={styles.container}>
              <FilterView
                locationProvided={locationProvided}
                totalResults={trialsMeta.total}
                minDistance={trialsMeta.minDistance}
                popover={popover}
                filterGroups={filterGroups}
                form={form}
              />
            </Form>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default TrialsTemplate;
