import styles from 'components/Separator/Separator.module.scss';
import React from 'react';
import { Separator } from 'reakit/Separator';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ReakitSeparator = ({ noSpacing, hide }) => {
  return (
    <Separator
      className={classNames(styles.separator, {
        [styles.noSpacing]: noSpacing,
        [styles.hide]: hide,
      })}
    />
  );
};

ReakitSeparator.propTypes = {
  noSpacing: PropTypes.bool,
  hide: PropTypes.bool,
};

ReakitSeparator.defaultProps = {
  noSpacing: false,
  hide: false,
};

export default ReakitSeparator;
